//Show expanded description
//TEST

$('.btn-more').click(function() {
  $(this)
    .closest('.wrapper--section')
    .next('.section__more')
    .animate(
      {
        height: 'toggle'
      },
      'slow'
    );
  if ($.trim($(this).text()) === 'MEHR ERFAHREN') {
    $(this).text('VERSTECKE INFOS');
    $(this).addClass('btn--expand--minus');
  } else {
    $(this).text('MEHR ERFAHREN');
    $(this).removeClass('btn--expand--minus');
  }
  return false;
});

// Show answers in FAQ

$('.faq__item').click(function() {
  $(this)
    .children('.faq__answer')
    .toggle();
  $(this).toggleClass('faq__item--expanded');

  return false;
});

// Scroll to anchor
var $root = $('html, body');
$('.lnk--nav, .lnk--logo, .btn--register').click(function() {
  if ($.attr(this, 'href') == '#features') {
    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top + 350
      },
      500
    );
  } else if ($.attr(this, 'href') == '#header') {
    $('html, body').animate(
      {
        scrollTop: 0
      },
      500
    );
  } else {
    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top - 84
      },
      500
    );
  }

  return false;
});

// Testimonials Carousel

$('.carousel').unslider();

// Show fix bar

$(document).scroll(function() {
  var y = $(this).scrollTop();
  if (y > 530) {
    if (!$('.wrapper--nav').hasClass('wrapper--fixed')) {
      $('.wrapper--nav')
        .hide()
        .addClass('wrapper--fixed')
        .fadeIn('slow');
    }
  } else {
    $('.wrapper--nav').removeClass('wrapper--fixed');
  }
});

// Video overlay

$('.header__play').click(function() {
  $('.overlay').fadeIn();
  $('#video')[0].src += '&autoplay=1';
});

$('.overlay__close, .overlay__wrapper').click(function() {
  $('.overlay').fadeOut();
  $('.overlay__video').empty();
  $('.overlay__video').append(
    '<iframe id="video" src="https://www.youtube.com/embed/E1jDvet0o9U?version=3&enablejsapi=1?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>'
  );
});

$(document).keyup(function(e) {
  if (e.keyCode == 27) {
    $('.overlay').fadeOut();
    $('.overlay__video').empty();
    $('.overlay__video').append(
      '<iframe id="video" src="https://www.youtube.com/embed/E1jDvet0o9U?version=3&enablejsapi=1?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>'
    );
  }
});

var submitting = false;
var validateForm = function() {
  if (submitting === true) {
    return false;
  }
  var form = $('#register-interest-form');
  var valid = true;
  $.each(
    form
      .find('input')
      .not('#submit')
      .not('#tos_accepted'),
    function(idx, input) {
      if ($(input).val() == '' || $(input).val() == null) {
        valid = false;
      }
    }
  );
  if (form.find('#tos_accepted').prop('checked') != true) {
    alert('Die Datenschutzerklärung muss gelesen und akzeptiert werden');
    return false;
  }
  if (valid === false) {
    alert('Bitte füllen Sie alle Felder aus.');
    return false;
  } else {
    window.ogVal = $('#submit').val();
    $('#submit').val('Ihre Anfrage wird bearbeitet');
    submitting = true;
    $.ajax({
      type: 'POST',
      url: 'https://junait.de/register_interest',
      data: $(form).serialize(), // serializes the form's elements.
      success: function(data) {
        $('#eoi-success')
          .show()
          .fadeOut(5000); // show response from the php script.
        form[0].reset();
        $('#submit').val(window.ogVal);
      }
    });
    //  e.preventDefault();
    return false;
  }
};
